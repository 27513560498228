import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import ViewportContext from "../utils/viewportContext";
import { throttle } from "throttle-debounce";

import "./film-scroller.scss";
import "./selected-film.scss";
import { getFilmShortVideoHref } from "../utils/getters";

const AUTOPLAY_VIDEO = true;

function isNearEndOfScroll(el, isHorizontal) {
    const NEAR_END_THRESHOLD = 50;

    if (isHorizontal) {
        const containerWidth = el.getBoundingClientRect().width;
        const scrollLeft = el.scrollLeft;
        const scrollWidth = el.scrollWidth;

        return (scrollWidth - (scrollLeft + containerWidth)) < NEAR_END_THRESHOLD;
    }

    const containerHeight = el.getBoundingClientRect().height;
    const scrollTop = el.scrollTop;
    const scrollHeight = el.scrollHeight;

    return (scrollHeight - (scrollTop + containerHeight)) < NEAR_END_THRESHOLD;
}

class FilmScroller extends React.Component {
    static contextType = ViewportContext;

    constructor(props) {
        super(props);

        // Hacky logic here. Basically trying to ensure the page always starts with a minimum of 8 items in list.
        let filmMulti = 1;
        const filmLength = props.films.length;
        if (filmLength === 1) {
            filmMulti = 8;
        } else if (filmLength === 2) {
            filmMulti = 4;
        } else if (filmLength === 3) {
            filmMulti = 3;
        } else if (filmLength < 7) {
            filmMulti = 2;
        }

        let films = [];
        for (let i=0; i < filmMulti; i++) {
            films = films.concat(props.films);
        }

        this.state = {
            selectedFilm: null,
            selectedFilmStartTime: 0,

            films: films,
        }

        this.scrollRef = React.createRef();

        this.restartAutoScrollThrottle = throttle(100, false, this._startScrollingFilms);

        this._renderFilmListItem = this._renderFilmListItem.bind(this);
        this._startScrollingFilms = this._startScrollingFilms.bind(this);
        this._stopScrollingFilms = this._stopScrollingFilms.bind(this);
        this._handleInfiniteScrolling = this._handleInfiniteScrolling.bind(this);
        this._selectFilm = this._selectFilm.bind(this);
        this._unselectFilm = this._unselectFilm.bind(this);
        this._handleVisiblityChange = this._handleVisiblityChange.bind(this);
        this._handleWheelEvent = this._handleWheelEvent.bind(this);
    }

    componentDidMount() {
        this._startScrollingFilms();

        document.addEventListener("visibilitychange", this._handleVisiblityChange);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.autoScroll !== this.props.autoScroll) {
            this.props.autoScroll
                ? this._startScrollingFilms()
                : this._stopScrollingFilms();
        }
        if (prevState.selectedFilm !== this.state.selectedFilm && !this.state.selectedFilm) {
            this._startScrollingFilms();
        }
    }

    componentWillUnmount() {
        this._stopScrollingFilms();
        document.removeEventListener("visibilitychange", this._handleVisiblityChange);
    }

    _handleVisiblityChange() {
        document.visibilityState === 'visible'
            ? this._startScrollingFilms()
            : this._stopScrollingFilms();
    }

    _startScrollingFilms() {
        if (!!this.state.selectedFilm) return null; // don't scroll if film is hovered / playing

        clearInterval(this.scrollInterval);

        this.scrollInterval = setInterval(() => {
            const scrollLeft = this.scrollRef.current.scrollLeft + 4;

            this.animationFrame = window.requestAnimationFrame( () => {
                if (!this.scrollRef.current) return window.cancelAnimationFrame(this.animationFrame);

                const scrollSettings = {
                    left: scrollLeft,
                    behavior: 'smooth'
                };

                // required on viewport change
                if (this.scrollRef.current.getBoundingClientRect().top !== 0) this.scrollRef.current.scroll({top: 0});

                this.scrollRef.current.scroll(scrollSettings);
            })
        },  50);
    }

    _stopScrollingFilms() {
        clearInterval(this.scrollInterval);
    }

    _handleInfiniteScrolling(e) {
        if (isNearEndOfScroll(this.scrollRef.current, this.context === 'largeScreen')) {
            let newFilmList = [...this.state.films, ...this.props.films];

            const maxFilmListLength = this.context === 'largeScreen' ? 25 : 15;
            if (newFilmList.length > maxFilmListLength) {
                if (this.context === 'largeScreen')
                    newFilmList = [...this.props.films, ...this.props.films, ...this.props.films];
                else
                    newFilmList = this.props.films;
            }

            this.setState({
                films: newFilmList,
            });
        }
    }

    _handleWheelEvent(e) {
        const DELTA_MODIFIER_PERCENTAGE = 0.5;
        const delta = e.nativeEvent.wheelDelta;

        // must stop auto scroller on wheel to give user full control
        this._stopScrollingFilms();

        const scrollLeft = this.scrollRef.current.scrollLeft;
        this.scrollRef.current.scroll({
            left: scrollLeft + (delta * DELTA_MODIFIER_PERCENTAGE * -1),
        })

        this.restartAutoScrollThrottle();
    }

    _selectFilm(e, film) {
        this.setState({
            selectedFilm: film,
            selectedFilmStartTime: e.target.currentTime,
        });
    }

    _unselectFilm() {
        this.setState({
            selectedFilm: null,
        });
    }

    _handleSelectedVideoMounting(element, filmStartTime = 0) {
        if (element !== null) {
            element.currentTime = filmStartTime;
        }
    }

    _renderFilmListItem(film, i) {
        const filmPath = film.path;
        const filmVideoShort = getFilmShortVideoHref(film);

        return (
            <div className={`film-list__film-container`} key={`${i}_film`}
                 onMouseEnter={this._stopScrollingFilms}
                 onMouseLeave={this._startScrollingFilms}
            >
                <div className={"film"}
                     onMouseEnter={(e) => this._selectFilm(e, film)}
                     onMouseLeave={this._unselectFilm}
                >
                    <Link to={filmPath}>
                        <video className={"film__video"} src={filmVideoShort}
                               loop autoPlay={AUTOPLAY_VIDEO} playsInline={true} disableremoteplayback="true" muted
                        />
                    </Link>
                </div>
            </div>
        );
    }

    _renderSelectedFilm() {
        if (!this.state.selectedFilm) return null;
        const filmVideoShort = getFilmShortVideoHref(this.state.selectedFilm);
        const filmStartTime = this.state.selectedFilmStartTime;

        return (
            <div className={"selected-film"}>
                <video className={"selected-film__film"} preload={"metadata"}
                       src={filmVideoShort}
                       ref={(el) => this._handleSelectedVideoMounting(el, filmStartTime)}
                       loop autoPlay={AUTOPLAY_VIDEO} playsInline={true} disableremoteplayback="true" muted
                />
            </div>
        );
    }

    render() {
        const films = this.state.films;
        const wheelHandler = this.context === 'largeScreen'
            ? this._handleWheelEvent
            : null;

        return (
            <>
                {this.context === 'largeScreen' && this._renderSelectedFilm()}

                <div className={'film-list-container'} >
                    <div className={"film-list"} ref={this.scrollRef}
                         onWheel={wheelHandler}
                         onScroll={this._handleInfiniteScrolling}
                    >
                        {films.map(this._renderFilmListItem)}
                    </div>
                </div>
            </>
        );
    }
}

FilmScroller.propTypes = {
    films: PropTypes.array.isRequired,
    autoScroll: PropTypes.bool,
};

FilmScroller.defaultProps = {
    autoScroll: false,
}

export default FilmScroller;
