import React, { useContext } from "react";
import { graphql } from "gatsby";

import SEO from "../components/seo/seo";
import FilmScroller from "../components/filmScroller/filmScroller";
import ViewportContext from "../components/utils/viewportContext";

const IndexPage = ({data}) => {
    const viewport = useContext(ViewportContext);
    const films = data.allFilm.edges.map(filmNode => filmNode.node);

    return (
        <>
            <SEO />

            <FilmScroller films={films} autoScroll={viewport === 'largeScreen'} />
        </>
    );
}

export default IndexPage;

export const query = graphql`
query HomePageQuery {
  allFilm {
    edges{
      node {
        path
        video_short {
          url
        }
      }
    }
  }
}
 `;
